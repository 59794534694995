@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/swiper/dist/css/swiper";
@import "node_modules/@fortawesome/fontawesome/styles.css";

//Global Styling
$theme-colors: (
    "blue":      #072d42,
    "yellow":    #fbaf42,
    "gray":      #464646,
    "lt-gray":   #ebebeb
);

body { background-color: #fff;color: theme-color('dk-gray'); }

h1, h2, h3, h4, h5, h6, .btn { font-family: 'Barlow Condensed', sans-serif; font-weight: 600; color: theme-color('blue');}
h1 { font-weight: 700; text-transform: uppercase;}
body { font-family: 'Barlow Condensed', sans-serif; font-weight: 400;font-size: 1em;color: theme-color('gray')}

h1 { font-size: 1.9em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.17em; }
h4 { font-size: 1.12em; }
h5 { font-size: .83em; }
h6 { font-size: .75em; }

a { color: theme-color('blue'); -webkit-transition: all .2s linear; transition: all .2s linear;font-size: 1.1em; }
a:hover { color: theme-color('yellow'); text-decoration: none; }

img { width: 100%; }

.btn { 
    display: inline-block;
    font-weight: 600;
    padding: 7px 40px; 
    margin: 5px 0px;
    font-size: 1.2em;
    color: theme-color('blue');
    border: solid 2px theme-color('yellow');
    border-radius: 0px 10px 0px 10px;
    text-transform: uppercase;
    background-color: theme-color('yellow'); 
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
.btn:hover { 
    background-color: transparent; 
    color: theme-color('blue');
}
a:not([href]):not([tabindex]) {
    color: theme-color('blue');
    text-decoration: none;
}
a:not([href]):not([tabindex]):hover {
    color: theme-color('blue');
}
p {
    font-size: 1.2em;
}
/* Main Menu */
#nav-header {
    padding: 20px 0px;
    background-color: theme-color('blue');
}
#nav-header nav {
    align-self: center;
    float: right;
}
#nav-wrapper {
    border-top: solid 1px #fbaf42;
    margin-top: -16px;
    padding-top: 10px;
}
#header-icons {
    float: right;
}
#header-icons a {
    color: theme-color('yellow');
    font-size: 1.2em;
}
#header-icons a:hover {
    color: #fff;
}
#header-icons a span {
    display: inline-block;
    margin-left: 5px; 
}
#header-icons > div {
    margin-left: 15px;
}
#header-icons a .svg-inline--fa {
    font-size: 30px;
}
#header-contact {
    float: right;
    text-align: right;
    color: theme-color('yellow');
    font-size: 1.2em;
}
.header-contact {
    display: none;
}
#main-menu {
    list-style: none;
    margin: 0;
    padding: 0;
}
#main-menu > li {
    position: relative;
    white-space: nowrap;
    display: inline-block;
    font-size: 1.3em;
    padding-left: 25px;
    border-left: solid 2px theme-color('blue');
    font-weight: 300;
    
    & a {
        color: #fff;
    }
    & a:hover {
        color: theme-color('yellow');
        
    }
    & span {
        color: #fff;
    }
} 
.menu-nonlink:hover {
    cursor: default;
    
}
#main-menu > li:hover .sub-nav {
    opacity: 1;
    visibility: visible;
}
#main-menu li a {
    color: theme-color('dk-gray');
    -webkit-transition: color .2 linear;
    transition: color .2s linear;
}
#home-nav-container #main-menu li a {
    color: theme-color('blue');
    -webkit-transition: color .2 linear;
    transition: color .2s linear;
}
#home-nav-container #main-menu > li > a:hover {
    color: theme-color('blue');
    text-decoration: none;
}
.sub-nav {
    list-style: none;
    position: absolute;
    z-index: 2;
    visibility: hidden;
    min-width: 150px;
    opacity: 0;
    padding-left: 0px;
    padding: 5px 0px;
    transition: all .2s linear;
    background-color: rgba(255, 255, 255, 1);
    left: 0;
    top: 35px;
    left: 25px;
}
.sub-nav > li {
    white-space: nowrap;
    background-color: transparent;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
.sub-nav > li > a {
    display: block;
    padding: 3px 10px;
    color: theme-color('blue') !important;
    text-decoration: none;
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
}
.sub-nav > li:hover a {
    color: theme-color('yellow') !important;
}
#site-logo img { 
    position: relative;
    z-index: 2;
    width: 220px;
}

/* Moblie Menu */
#mobile-menu-icon {
    padding-left: 15px;
    float: right;
}
#mobile-menu-icon svg {
    width: 50px;
    fill: #fff;
    max-height: 30px;
}
#mobile-menu-icon svg:hover {
    cursor: pointer;
}
#home-nav-container #mobile-menu-icon svg {
    fill: #fff;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
#home-nav-container #mobile-menu-icon svg.home-nav-scrolled {
    fill: theme-color('dk-gray');
}
#mobile-nav-btn {
    align-self: center;
}
#mobile-menu {
    position: fixed;
    width: 200px;
    height: 100vh;
    background-color: theme-color('blue');
    z-index: 3;
    left: -303px;
    top: 0;
    box-shadow: 4px 0 10px -1px #000;
    transition: all .5s ease-in-out;  
}
#mobile-menu.open{
    left: 0px;
}
.mobile-menu-txt {
    text-align: right;
    padding-right: 15px;
    opacity: 0;
    font-size: 22px;
    position: absolute;
    right: 0;
    top: 500px;
    -o-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}
.mobile-menu-txt ul > li {
    list-style: none;
}
.mobile-menu-txt ul > li > a {
    color: #fff;
}
.mobile-menu-txt.open {
    opacity: 1;
    bottom: auto;
    top: 70px;
}
#close-mobile {
    text-align: right;
    font-size: 28px;
    color: #fff;
    padding-right: 30px;
    padding-top: 25px;
}
#close-mobile:hover {
    cursor: pointer;
}
#menu-mobile-menu li {
    position: relative;
    padding: 5px 15px;
    border-right: solid 0px;
    color: #fff;
}
/* Footer */
#footer-menu {
    padding: 50px;
    background-color: theme-color('blue'); 
    
    & h3 {
        color: #fff;
    }
    & a {
        color: #fff;
    }
    & a:hover {
        color: theme-color('yellow');
    }
    & address {
        color: #fff;
    }
    & .underline {
        height: 4px;
        width: 34px;
        background-color: #fbaf42;
        margin-bottom: 4px
    }
}
#copyright {
    margin-top: 15px;
    color: #fff;
    
    & #foot-divider {
        width: 100%;
        height: 1px;
        margin: 15px;
        background-color: theme-color('yellow');
    }
    & #amg-logo  {
        text-align: right;
        
        & img {
            max-width: 190px;
        }
    }
}
/* Forms */
#formContainer input {
    width: 100%;
    margin: 4px 0px;
    padding: 5px 5px;
    color: theme-color('blue');
}
#formContainer .submit  { 
    display: inline-block;
    font-weight: 700;
    font-size: 1.3em;
    padding: 3px 40px; 
    margin: 5px 0px;
    color: theme-color('blue');
    border: solid 2px theme-color('yellow');
    border-radius: 0px 10px 0px 10px;
    background-color: theme-color('yellow'); 
    max-width: 250px;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
#formContainer .submit:hover { 
    cursor: pointer;
    background-color: transparent; 
}
#formContainer .state {
    margin: 5px 0px;
    height: 34px;
    width: 100%;
    min-width: 65px
}
#formContainer .message {
    width: 100%;
    margin: 4px 0px;
    height: 150px;
}
.responseMessage {
    background-color: #fff;
    margin: 5px 0px;
    padding: 15px;
    color: green;
    display: none;
    text-align: center;
}

/* New Doctor Page */
#steps-wrapper {
    display: flex;
    margin: auto;
    padding: 35px 0px;
}
#step-conent-wrapper h2 {
    font-weight: 700;
}
#step-conent-wrapper .btn {
    color: theme-color('dk-gray');
}
#step-conent-wrapper .btn:hover {
    color: #fff;
}
.steps-flex {
    display: flex;
}
.step-circle {
    display: flex;
    color: #fff;
    background-color: theme-color('dk-gray');
    text-align: center;
    height: 39px;
    width: 39px;
//    -webkit-transition: all .2s linear;
//    transition: all .2s linear;
}
.step-circle div { 
    align-self: center;
    margin: auto;
    font-size: 16px;
    padding-bottom: 3px;
    line-height: 0;
    font-weight: 700;
}
.step-circle.active-step {
    color: theme-color('red');
    background-color: #fff;
}
.step-circle.expired-step {
    color: theme-color('red');
    background-color: #fff;
}
#step-1 {
    z-index: 6;
}
#step-2 {
    z-index: 5;
}
#step-3 {
    z-index: 4;
}
#step-4 {
    z-index: 3;
}
#step-5 {
    z-index: 2;
}
#steps-wrapper div:nth-child(1) div {
    border-bottom-left-radius: 25%;
    border-top-left-radius: 25%;
}
#steps-wrapper div:nth-child(n+2) div {
    padding-left: 15px;
}
#steps-wrapper div:nth-child(5) div {
    border-bottom-right-radius: 25%;
    border-top-right-radius: 25%;
    width: 110px;
}
.nav-circle {
    height: 7px;
    width: 7px;
    float: left;
    margin: 0px 3.5px;
    border-radius: 50%;
    border: solid 1.8px theme-color('blue');
    background-color: #fff;
//    -webkit-transition: all .4s linear;
//    transition: all .4s linear;
}
.nav-circle.active-circle {
    background-color: theme-color('blue');
}
.nav-circle.solid-circle {
    background-color: theme-color('blue');
}
.nav-circle-wrapper {
    display: flex;
    align-self: center;
    padding: 0px 0px;
}
#step-conent-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
#step-conent-wrapper div {
    text-align: center;
    color: #fff;
}
#step-conent-wrapper h2, #step-conent-wrapper h3 {
    color: #fff;
}
.center-divide {
    width: 100%;
    max-width: 300px;
    margin: auto;
    height: 1px;
    background-color: #76777a;
    margin-bottom: 5px;
}
.step-content-block {
    grid-column-start: 1;
    grid-row-start: 1;
}
.step-content-wrap {
    padding: 25px 0px;
    width: 100%;
    margin: auto;
    max-width: 800px;
}
.hide-content {
    display: none;
}
.arrow-animate {
    animation: animate-fa-arrow 2s ease infinite;
}
@keyframes animate-fa-arrow {
    0% {
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
    }
    6% {
        -webkit-transform: translate(5px,0);
        transform: translate(5px,0);
    }
    12% {
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
    }
    18% {
        -webkit-transform: translate(5px,0);
        transform: translate(5px,0);
    }
    24% {
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
    }
    30% {
        -webkit-transform: translate(5px,0);
        transform: translate(5px,0);
    }
    100%, 36% {
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
    }
}

/* Swiper */
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
   background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-pagination-bullet-active {
    background: #fff;
}

/* Zip Code Checker */
#zipcode_Field {
    margin: auto;
    width: 100%;
    max-width: 300px;
}

/* Panels (Prduct Detials) */
.panel-group {
    position: relative;
}
.panel-group:before {
    content: "";
    position: absolute;
    top: 20px;
    bottom: 17px;
    left: 18px;
    border-left: solid 2px theme-color('blue');
    z-index: 0;
}

.panel h3 {
    text-transform: capitalize;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
}
.panel h3:hover {
    cursor: pointer;
}
.panel h3:before {
    content: "";
    background-color: theme-color('blue');
    border: 1px solid theme-color('blue');
    width: 14px;
    height: 14px;
    margin: 2px 12px 0;
    position: relative;
    z-index: 10;
    border-radius: 4px;
}
.panel > div[aria-expanded="true"] h3:before {
    background-color: theme-color("yellow");
}
.panel > div[aria-expanded="true"] h3 {
    color: theme-color("blue");
}
.panel-collapse .panel-body {
    padding-left: 40px;
}

/* Masonry */
.masonry {
    column-count: 2;
    column-gap: 1em;
}
.masonry > * {
    display: inline-block;
    margin: 0 0 1em;
    width: 100%;
    overflow: hidden;
}
.masonry > * img {
    width: 100%;
    transition: transform 0.2s ease-in-out;
}
.masonry > *:hover img {
    cursor: pointer;
    transform: scale(1.1, 1.1);
}

/* Jquery Modal */
.blocker {
    z-index: 2 !important;
}
.modal {
    overflow: visible !important;   
    max-width: 90%;
    padding: 20px;
}
.modal p {
    margin-top: 15px;
    margin-bottom: 0px;
}

/* Home-page */
#home-intro {
    background-image: url('/img/7819-Homepage-Capital-Background-Image.jpg'); 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    padding-top: 40px;
    
    & .container, & .row {
        height: 100%;
    }
}
#home-intro-grid { 
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    width: 100%;
    grid-gap: 2em;
    padding-right: 15px;
    padding-left: 15px;    

}
#home-intro-labExpert {
    grid-column-end: span 2;
    grid-row-end: span 2;
    display: grid;
    background-color: rgba(251, 175, 66, .9);
    padding: 30px;
    display: none;
    
    & div {
        align-self: center;
    }
    & div div:nth-child(1) {
        text-align: center;
    }
    & img {
        max-width: 520px;
    }
    & h1 {
        font-size: 3em;
    }
    & h2 {
        font-weight: 300;
        font-size: 2em;  
        color: theme-color('gray');
    }
}
#home-intro-newDoc {
    display: grid;
    background-image: url('/img/7819-Homepage_Handshake.jpg');
    background-size: cover;
    background-position: center;
    border: 4px solid rgba(251, 175, 66, 0.9);
    transition: all .2s linear;
    text-align: center;
    
    & div {
        padding: 15px;
        align-self: center
    }
    &:hover {
        border: 4px solid rgba(7, 45, 66, 0.9);
    }
    & h2 {
        font-weight: 300;
        color: theme-color('gray');
    }
}
#home-intro-digFile {
    display: grid;
    background-image: url('/img/7819-Homepage-DigitalSubmission.png');
    background-size: cover;
    background-position: center;
    border: 4px solid rgba(251, 175, 66, 0.9);
    transition: all .2s linear;
    text-align: center;
    
    & div {
        padding: 0px 5px;
        align-self: center
    }
    &:hover {
        border: 4px solid rgba(7, 45, 66, 0.9);
    }
    & h2 {
        font-weight: 300;
        color: theme-color('gray');
    }
}
#home-header-arrow { 
    grid-column-end: span 1;
    align-self: center;
}
.arrow {
    text-align: center;
    color: #fff;
    font-size: 1.3em;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}
#home-intro-txt {
    background-color: theme-color('lt-gray');
    padding: 50px 0px;
}
#home-intro-newDoc-cta {
    padding: 50px 0px;
    
    & .container .row div:nth-child(2){
        text-align: center;
        display: grid;
    }
}
#home-products-cta {
    background-image: url('/img/7819-Homepage-Sunflower-Background-Image.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 50px 0px;
    
    & .container .row div:nth-child(1) {
        display: grid;
    }
    & .container .row div:nth-child(1) div {
        align-self: center;
    }
    & h1 {
        color: #fff;
        font-size: 4em;
    }
}
#product-grid{
    display: grid;
    grid-gap: 1.5em;
    grid-template-columns: repeat(2, 1fr); 
}
.home-products-grid {
    display: grid;
    grid-gap: 1.5em;
    grid-template-columns: repeat(1, 1fr); 
    
    & .product-cta {
        background-repeat: no-repeat;
        background-position: center;
    }
    & div div a {
        position: absolute;
        height: 100%;
        width: 100%;
    }
    & #ips-emax {
        background-image: url('/img/7819-Homepage-Thumbnails-LargeProduct-IPSemax.jpg');
    }
    & #our-services {
        background-image: url('/img/7819-Homepage-Thumbnails-SmallProduct-OurServices.jpg');
    }
    & #custom-abut {
        background-image: url('/img/7819-Homepage-Thumbnails-SmallProduct-CustomAbutments.jpg');
    }
    & #fcz {
        background-image: url('/img/7819-Homepage-Thumbnails-LargeProduct-FCZ.jpg');
    }
    
    & .product-cta-content-227 {
        height: 227px;
        overflow: hidden;
        position: relative;
    }
    & .product-cta-content-157 {
        height: 157px;
        overflow: hidden;
        position: relative;
    }
    & .product-heading {
        background-color: theme-color('yellow');
        text-align: center;
        position: absolute;
        width: 100%;
        top: calc(100% - 44px);
        height: 44px;
        transition: all .5s ease-in-out;
        
        & h1 {
            font-size: 1.8em !important;
            margin: 0px;
            padding: 5px 0px;
            color: theme-color('blue') !important;
        }
    }
    & .product-cta-content-227:hover .product-heading {
        top: -44px;
        cursor: pointer;
    }
    & .product-cta-content-157:hover .product-heading {
        top: -44px;
        cursor: pointer;
    }
    & .product-copy {
        display: grid;
        background-color: theme-color('yellow');
        text-align: center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 100%;
        transition: all .5s ease-in-out;
        
        & p {
            font-size: 1em;
            width: 90%;
            margin: auto;
            align-self: center;
        }
    }
    & .product-cta-content-227:hover .product-copy {
        top: 0;
        cursor: pointer;
    }
    & .product-cta-content-157:hover .product-copy {
        top: 0;
        cursor: pointer;
    }
}
/* Internal Pages */
#page-title {
    padding: 35px 0px;
    
    & #title-underline {
        margin: 5px 0 15px 0px;
        height: 3px;
        background-color: theme-color('yellow'); 
    }
    .title-underline {
        margin: 5px 0 15px 0px;
        height: 3px;
        background-color: theme-color('yellow'); 
    }
}
#page-content {
    padding-bottom: 50px;
}
#contactForm-wrapper {
    padding: 25px;
    border-radius: 0px 25px 0px 25px;
    background-color: theme-color('lt-gray');
      
    & img {
        border: solid 1px #000;
    }
}
#address-wrap {
    padding: 25px;
    margin-top: 4px;
    border-radius: 0px 25px 0px 25px;
    background-color: theme-color('blue'); 
    text-align: center;
    
    & h2 {
        color: theme-color('yellow');
        text-transform: uppercase;
    }
    & p {
        color: #fff;
    }
}
.product-listing {
    & .container .row div {
        padding: 15px;
    }
}
/* Product Pages */
.product-list-thumb {
    padding: 25px;
    background-color: theme-color('lt-gray');
    border: solid 3px theme-color('lt-gray');
    border-radius: 0px 25px 0px 25px;
    text-align: center;
    transition: all .2s linear;
    
    &:hover {
        background-color: theme-color('blue');
        border: solid 3px theme-color('yellow');
    }
    & img {
        margin-top: -40px;
        width: 90%;
    }
    & h2 {
        margin-top: -15px; 
        margin-bottom: 0px;
    }
    &:hover h2 {
        color: theme-color('yellow');
    }
}

/* New Doctor */
#newDoc-wrap {
    padding: 50px;
    border-radius: 0px 25px 0px 25px;
    background-color: theme-color('lt-gray');
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    overflow: hidden;
    position: relative;
    transition: all .2s linear;
    
    & #title-underline {
        margin: 5px 0 15px 0px;
        height: 3px;
        background-color: theme-color('yellow'); 
        max-width: 75px;
    }
    & h2 {
        text-transform: uppercase;
    }
    & .send-step {
        grid-column-start: 1;
        grid-row-start: 1;
    }
    & .send-step .row {
        padding-bottom: 25px;
    }
    & .send-step .d-flex {
        justify-content:  space-between;
    }
    & .step-number {
        display: inline-block;
        font-weight: 600;
        padding: 7px 40px; 
        margin: 5px 0px;
        font-size: 1.5em;
        color: theme-color('yellow');
        border-radius: 0px 15px 0px 15px;
        text-transform: uppercase;
        background-color: theme-color('blue'); 
        display: none;
        white-space: nowrap;
    }
}
 /* form input */
input[type="radio"], input[type="checkbox"] {
    min-width: 15px;
}

/* sm devices */ 
@media screen and (min-width: 576px) { 
    .step-number { display: block !important; }
} 
 
/* md devices */ 
@media screen and (min-width: 768px) { 
    #home-intro-grid { grid-template-columns: repeat(3, 1fr); }
    #home-intro-labExpert{ display: block }
    #home-header-arrow { grid-column-end: span 3 }
    #site-logo img { width: 275px; }
    .header-contact { display: inline}
    #nav-wrapper { margin-top: -19px; }
} 
 
/* lg devices */ 
@media screen and (min-width: 992px) { 
    
} 

/* xl devices */ 
@media screen and (min-width: 1200px) { 
    #home-intro-digFile div { padding: 30px; }
    #home-intro-newDoc div { padding: 30px; }
} 